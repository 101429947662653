import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/auth/models/auth-response-model';
import { LoginModel } from 'src/app/auth/models/login-model';
import { ResetPasswordModel } from 'src/app/auth/models/reset-password-model';
import { HandleError, HttpErrorHandler } from 'src/app/network/http-error-handler.service';
import { AuthScope } from 'src/app/shared/models/enums/auth-scope.enum';
import { UserModel } from 'src/app/shared/models/user-model';
import { environment } from 'src/environments/environment';
import { constants } from 'src/utils/constants';
import { DataService } from '../data/data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private isForce = false;

  private handleError: HandleError;
  private unlockUrl: string;
  private confirmationUrl: string;
  private loginUrl: string;
  private logoutUrl: string;
  private passwordUrl: string;
  private meUrl: string;
  private profileUrl: string;

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler, private dataService: DataService) {
    this.loginUrl = `${ environment.api.hostName }${ constants.endpoints.auth }`;
    this.logoutUrl = `${ environment.api.hostName }${ constants.endpoints.revoke }`;
    this.passwordUrl = `${ environment.api.hostName }${ constants.endpoints.users }${ constants.endpoints.password }`;
    this.confirmationUrl = `${ environment.api.hostName }${ constants.endpoints.users }${ constants.endpoints.confirmation }`;
    this.unlockUrl = `${ environment.api.hostName }${ constants.endpoints.users }${ constants.endpoints.unlock }`;
    this.meUrl = `${ environment.api.hostName }${ environment.api.apiFolderPath }${ environment.api.apiVersion }` +
      `${ constants.endpoints.me }`;
    this.profileUrl = `${ environment.api.hostName }${ environment.api.apiFolderPath }${ environment.api.apiVersion }` +
      `${ constants.endpoints.profile }`;
    this.handleError = httpErrorHandler.createHandleError();
  }

  login(loginModel: LoginModel, scope: string): Observable<AuthResponseModel> {
    const inputData = {
      username: loginModel.email,
      password: loginModel.password,
      grant_type: constants.grantType.password,
      client_id: this.getClientId(scope),
      client_secret: this.getClientSecret(scope),
      scope
    };
    return this.http.post<AuthResponseModel>(this.loginUrl, inputData)
      .pipe(
        catchError(this.handleError<AuthResponseModel>())
      );
  }

  refreshToken(authResponse: AuthResponseModel): Observable<AuthResponseModel> {
    const inputData = {
      refresh_token: authResponse.refresh_token,
      grant_type: constants.grantType.refresh_token,
      client_id: this.getClientId(this.getScope(this.dataService.getScope())),
      client_secret: this.getClientSecret(this.getScope(this.dataService.getScope())),
    };
    return this.http.post<AuthResponseModel>(this.loginUrl, inputData).pipe(
      catchError(this.handleError<AuthResponseModel>())
    );
  }

  /**
   * Get logged in user details
   */
  getMe(): Observable<UserModel> {
    let params = new HttpParams();
    if (this.isForce) {
      params = params.append('force', this.isForce.toString());
    }
    return this.http.get<UserModel>(this.meUrl, { params }).pipe(
      catchError(this.handleError())
    );
  }

  /**
   * Update logged in user details
   */
  updateMe(userModel: UserModel): Observable<UserModel> {
    const inputData = {
      user: userModel
    };
    return this.http.put<UserModel>(this.profileUrl, inputData).pipe(
      catchError(this.handleError())
    );
  }

  logout() {
    const authData = JSON.parse(localStorage.getItem(constants.localStorage.authResponse));
    if (authData) {
      return this.http.post<any>(this.logoutUrl, {
        client_id: this.getClientId(this.getScope(this.dataService.getScope())),
        client_secret: this.getClientSecret(this.getScope(this.dataService.getScope())),
        token: authData.access_token
      }).pipe(
        catchError(this.handleError())
      );
    }
  }

  getAuthorizationToken() {
    const authData = JSON.parse(localStorage.getItem(constants.localStorage.authResponse));
    if (authData) {
      return authData.token_type + ' ' + authData.access_token;
    }
    return '';
  }

  /**
   * Send email id to server for password reset
   * @param userModel user
   */
  public forgotPassword(userModel: UserModel) {
    const inputData = {
      user: userModel,
    };
    return this.http.post(this.passwordUrl, inputData).pipe(
      catchError(this.handleError())
    );
  }

  /**
   * get request to API for confirm user
   * @param confirmationToken token to confirm user
   */
  confirmUser(confirmationToken: string): Observable<UserModel> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('confirmation_token', confirmationToken);
    return this.http.get<UserModel>(this.confirmationUrl, { params: queryParams }).pipe(
      catchError(this.handleError())
    );
  }

  /**
   * patch request to API for reset password
   * @param resetPasswordModel request model
   */
  resetPassword(resetPasswordModel: ResetPasswordModel) {
    const inputData = {
      user: resetPasswordModel
    };
    return this.http.patch(this.passwordUrl, inputData).pipe(
      catchError(this.handleError())
    );
  }

  /**
   * get request to API for user unlocking
   * @param unlockToken token to unlock user
   */
  userUnlock(unlockToken: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('unlock_token', unlockToken);
    return this.http.get(this.unlockUrl, { params: queryParams }).pipe(
      catchError(this.handleError())
    );
  }

  /**
   * Choose clientId based on accountType
   * @param scope sccope
   */
  private getClientId(scope: string) {
    switch (scope) {
      case AuthScope.SUPER_ADMIN: return environment.api.admin.clientId;
      case AuthScope.BUSINESS_PARTNER: return environment.api.pos.clientId;
      default: return environment.api.admin.clientId; // TODO change to customer client id
    }
  }

  /**
   * Choose clientSecret based on accountType
   * @param scope select
   */
  private getClientSecret(scope: string) {
    switch (scope) {
      case AuthScope.SUPER_ADMIN: return environment.api.admin.clientSecret;
      case AuthScope.BUSINESS_PARTNER: return environment.api.pos.clientSecret;
      default: return environment.api.admin.clientSecret; // TODO change to customer client secret
    }
  }

  private getScope(scope: string): string {
    if (scope === '/admin') {
      return AuthScope.SUPER_ADMIN;
    } else if (scope === '/pos') {
      return AuthScope.BUSINESS_PARTNER;
    } else {
      return AuthScope.SUPER_ADMIN; // TODO change to customer scope
    }
  }

  /**
   * Function to set the is force flag.
   * @param value is force flag
   */
  setForce(value: boolean = true) {
    this.isForce = value;
  }

  /**
   * change password of logged user
   * @param userModel user model
   */
  changePassword(password: string): Observable<UserModel> {
    const inputData = {
      user: {
        password
      }
    };
    return this.http.put<UserModel>(this.profileUrl, inputData).pipe(
      catchError(this.handleError())
    );
  }

}
