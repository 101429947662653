import { Pipe, PipeTransform } from '@angular/core';
import { Emirates } from '@models/enums/emirates.enum';

@Pipe({
  name: 'emirates'
})
export class EmiratesPipe implements PipeTransform {

  transform(value: string): string {
    return this.getEmirates(value);
  }

  /**
   * get the label for corresponding emirates
   * @param value selected emirate value
   */
  private getEmirates(value: string) {
    switch (value) {
      case Emirates.ABU_DHABI: return 'Abu Dhabi';
      case Emirates.AJMAN: return 'Ajman';
      case Emirates.DUBAI: return 'Dubai';
      case Emirates.FUJAIRAH: return 'Fujairah';
      case Emirates.RAS_AL_KHAIMAH: return 'Ras al-Khaimah';
      case Emirates.SHARJAH: return 'Sharjah';
      case Emirates.UAE: return 'UAE';
      case Emirates.UMM_AL_QUWAIN: return 'Umm al-Quwain';
    }
  }

}
