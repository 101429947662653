export const constants = {
    grantType: {
        password: 'password',
        refresh_token: 'refresh_token'
    },
    endpoints: {
        auth: '/oauth/token',
        revoke: '/oauth/revoke',
        me: '/me',
        profile: '/profile',
        users: '/users',
        admin: '/admin',
        pos: '/pos',
        crm: '/crm',
        shops: '/shops',
        uoms: '/uoms',
        uom_categories: '/uom_categories',
        iapp_accounts: '/iapp_accounts',
        iapp_account_enterprises: '/iapp_account_enterprises',
        iapp_account_business_partners: '/iapp_account_business_partners',
        iapp_account_customers: '/iapp_account_customers',
        product_categories: '/product_categories',
        product_templates: '/product_templates',
        service_templates: '/service_templates',
        product_template_attrs: '/product_template_attrs',
        product_attributes: '/product_attributes',
        pos_categories: '/pos_categories',
        banners: '/banners',
        notifiers: '/app_notifiers',
        products: '/products',
        services: '/services',
        taxes: '/taxes',
        product_attribute_values: '/product_attribute_values',
        tax_groups: '/tax_groups',
        account_accounts: '/account_accounts',
        password: '/password',
        confirmation: '/confirmation',
        unlock: '/unlock',
        iapp_account_banks: '/iapp_account_banks',
        product_template_variants: '/product_template_variants',
        product_products: '/product_products',
        product_services: '/product_services',
        finance: '/finance',
        accounts: '/accounts',
        account_groups: '/account_groups',
        account_transactions: '/journals',
        orders: '/orders',
        bookings: '/bookings',
        promotions: '/promotions',
        sale: '/sale',
        inventory: '/inventory',
        receipts: '/receipts',
        tags: '/tags',
        tickets: '/tickets',
        comments: '/comments',
        leads: '/leads',
        service: '/service_templates',
        variants: '/product_services',
        service_bays: '/service_bays',
        membership: '/memberships',
        product_brands: '/product_brands',
        iapp_accounts_product_products: '/iapp_accounts_product_products',
        bank: '/banks',
        brand: '/product_brands',
        admin_report: '/report',
        admin_dashboard: '/dashboards',
        pos_report: '/report',
        pos_dashboard: '/dashboards',
        devices: 'devices',
        report: '/report',
        pos_earnings: '/pos_earnings',
        earnings: '/earnings',
        account_determinations: '/account_determinations',
        enquiries: '/enquiries',
        issues: '/issues',
        stocks: '/stocks',
        order_histories: '/order_histories',
        count: '/count',
        points: '/points',
        gmaps_base_url: 'https://www.google.com/maps/search/?api=1&query=',
        service_requests: '/service_requests',
        contact_us: '/contact_us',
        bulk_create: '/bulk_create',
        vehicles: '/vehicles',
        vehicle_iapp_accounts: '/vehicle_iapp_accounts',
        notifications: '/notifications',
        bulk_adjust_stock: '/bulk_adjust_stock',
        customer_registration: '/customer_registration',
        customer_vehicle: '/customer_vehicle'
    },
    localStorage: {
        authResponse: 'authResponse',
        accountId: 'accountId',
        tokenExpiresTime: 'tokenExpiresTime',
        accountRole: 'accountRole',
        productsPaginationState: 'productsPaginationState',
        selectedBusinessPartners: 'selectedBusinessPartners',
        selectedProductVariant: 'selectedProductVariant',
        redirectFromVehiclesTab: 'redirectFromVehiclesTab'
    },
    sessionStorage: {
        onboard_status: 'onboard_status',
        new_account_id: 'new_account_id',
        new_shop_id: 'new_shop_id'
    },
    firebase: {
        apiKey: 'AIzaSyBcsyRe6fOueWq5sMjB_fwh1_V9FXNNqdw',
        authDomain: 'mytyre-269907.firebaseapp.com',
        databaseURL: 'https://mytyre-269907.firebaseio.com',
        projectId: 'mytyre-269907',
        storageBucket: 'mytyre-269907.appspot.com',
        appId: '1:673348015490:web:6ed2d97fa2482ee8b52b9b',
        messagingSenderId: '673348015490',
        measurementId: 'G-2QP7N6P7X9'
    }
};
