import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeConversion'
})
export class TimeConversionPipe implements PipeTransform {

  timelineAttrValue: string;

  transform(value: number): string {
    return this.getLabel(value);
  }

  private getLabel(value: number) {
    const year = value / (365 * 86400);
    value = value % (365 * 86400); // get Year
    const month = value / (30 * 86400);
    value = value % (30 * 86400); // get  month
    const week = value / (7 * 86400);
    value = value % (7 * 86400); // get week
    const day = value / 86400;
    value = value % 86400; // get day
    const hour = value / 3600;
    value = value % 3600; // get hour
    const minutes = value / 60;
    value = value % 60; // get minutes
    const yearValue = Math.floor(year);
    const monthValue = Math.floor(month);
    const weekValue = Math.floor(week);
    const dayValue = Math.floor(day);
    const hourValue = Math.floor(hour);
    const minValue = Math.floor(minutes);
    const secValue = Math.floor(value);
    this.timelineAttrValue = '';
    // remove value with zero
    if (yearValue !== 0) {
      this.timelineAttrValue += yearValue + 'years' + '  ';
    }
    if (monthValue !== 0) {
      this.timelineAttrValue += monthValue + 'months' + '  ';
    }
    if (weekValue !== 0) {
      this.timelineAttrValue += weekValue + 'weeks' + '  ';
    }
    if (dayValue !== 0) {
      this.timelineAttrValue += dayValue + 'days' + '  ';
    }
    if (hourValue !== 0) {
      this.timelineAttrValue += hourValue + 'hours' + '  ';
    }
    if (minValue !== 0) {
      this.timelineAttrValue += minValue + 'minutes' + '  ';
    }
    if (secValue !== 0) {
      this.timelineAttrValue += secValue + 'seconds' + '  ';
    }
    return this.timelineAttrValue;
  }

}
