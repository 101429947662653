import { Pipe, PipeTransform } from '@angular/core';
import { UserRole } from 'src/app/shared/models/enums/user-role.enum';

@Pipe({
  name: 'userRole'
})
export class UserRolePipe implements PipeTransform {

  transform(value: string): string {
    return this.getLabel(value);
  }

  /**
   * get the label for corresponding user role
   * @param value selected user role value
   */
  private getLabel(value: string) {
    switch (value) {
      case UserRole.OWNER: return 'Owner';
      case UserRole.ADMIN: return 'Admin';
      default: return 'Staff';
    }
  }
}
