import { Pipe, PipeTransform } from '@angular/core';
import { OrderItemType } from 'src/app/shared/models/enums/order-item-type.enum';

@Pipe({
  name: 'orderItemType'
})
export class OrderItemTypePipe implements PipeTransform {

  transform(value: string): string {
    return this.getLabel(value);
  }

  /**
   * get the label for corresponding kind type
   * @param value selected kind type value
   */
  private getLabel(value: string) {
    switch (value) {
      case OrderItemType.CONSUMABLE_PRODUCT: return 'Consumable Product';
      case OrderItemType.STORABLE_PRODUCT: return 'Storable Product';
      case OrderItemType.SERVICE: return 'Service';
      default: return;
    }
  }
}
