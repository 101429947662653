import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FileType } from 'src/app/shared/models/enums/file-type.enum';
import { FileDataModel } from 'src/app/shared/models/file-data-modal';
import { AttachmentService } from 'src/app/shared/services/attachments/attachment.service';
@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent {

  files: FileDataModel[] = [];
  fileType = FileType;
  fileTypeError = false;

  @Input() url: string;  // api
  @Input() type: string; // image types (logo ,image_gallery_primary)
  @Input() key: string; // FormData key
  @Input() isMultiple: boolean; // multiple files or not
  @Input() accept: string; // file accept extension (.jpg, .png, .docx)
  @Output() fileUploaded = new EventEmitter<string>();
  @Output() imageClicked = new EventEmitter<FileDataModel>();
  @Input() isAdd = false;
  @Input() isBadge = false;
  isShow = false;

  constructor(private domSanitizer: DomSanitizer, private attachmentService: AttachmentService) { }

  /**
   * function to upload files
   */
  uploadFile(id: number, attachmentId: number = null) {
    this.isShow = true;
    const files = this.files.filter(fileDataModel => fileDataModel.file)?.map(fileDataModel => fileDataModel.file);
    if (files.length) {
      if (this.isAdd) {
        return this.attachmentService.upload(this.key, `${ this.url }`, this.type, files, attachmentId).subscribe((res: string) => {
          this.fileUploaded.emit(res);
          this.isShow = false;
        });

      }
      // condition implementing new way of image upload
      else if (this.isBadge) {
        return this.attachmentService.upload(this.key, `${ this.url }/${ id }/update_badge`, this.type, files, attachmentId).subscribe((res: string) => {
          this.fileUploaded.emit(res);
          this.isShow = false;
        });
      }
      else {
        return this.attachmentService.upload(this.key, `${ this.url }/${ id }`, this.type, files, attachmentId).subscribe((res: string) => {
          this.fileUploaded.emit(res);
          this.isShow = false;
        });
      }
    }
    this.isShow = false;
  }

  /**
   * handles file change event
   */
  fileChange(event: FileList) {

    if (event.length > 0) {
      const file = event[0];
      let preview: any;
      const fileExt = file.name.split('.').pop();
      if (fileExt && this.accept.includes(fileExt)) {
        if (this.type === this.fileType.DOCUMENT) {
          preview = '../../../../assets/doc-logo.svg';
        } else {
          // Bypass security and trust the given value to be a safe resource URL.
          preview = this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file));
        }
        const fileData: FileDataModel = { preview, file };
        if (this.isMultiple) {
          this.files.push(fileData);
        } else {
          this.files = [fileData];
        }
        this.fileTypeError = false;
      } else {
        this.fileTypeError = true;
      }
    }
  }

  addFile(fileDataModel: FileDataModel) {
    this.files.push(fileDataModel);
  }

  /**
   * handles file remove
   */
  deleteAttachment(index: number) {
    const fileDataModel = this.files[index];
    this.files.splice(index, 1);
    if (fileDataModel && fileDataModel.attachment) {
      this.attachmentService.deleteAttachment(this.key, `${ this.url }/${ fileDataModel.attachment.attachable_id }`,
        fileDataModel.attachment.id).toPromise();
    }
  }

  onClickImage(file: FileDataModel) {
    this.imageClicked.emit(file);
  }

}
