<a *ngIf="navItem" mat-list-item [ngStyle]="{'padding-left': (depth * 12) + 'px'}" (click)="onItemSelected(navItem)"
    [ngClass]="{'active': navItem.link ? router.isActive(navItem.link, true): false, 'expanded': expanded}"
    class="menu-list-item rootMenuParent">
    <mat-icon class="routeIcon" [color]="router.isActive(navItem.link, true)? 'primary' : ''">{{navItem.icon}}
    </mat-icon>
    {{navItem.name}}
    <span fxFlex *ngIf="navItem.children && navItem.children.length">
        <span fxFlex></span>
        <mat-icon class="rootMenuStyle" [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">
            expand_more
        </mat-icon>
    </span>
</a>
<div *ngIf="expanded">
    <app-menu-list-item *ngFor="let child of navItem.children" [navItem]="child" [depth]="depth+1">
    </app-menu-list-item>
</div>
