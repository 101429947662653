import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ChangePasswordComponent } from '@components/change-password/change-password.component';
import { UserModel } from 'src/app/shared/models/user-model';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  userModel: UserModel;

  constructor(
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getUserDetails();
  }

  /**
   * Get User Details
   */
  getUserDetails() {
    this.authService.getMe().subscribe(res => {
      this.userModel = res;
    });
  }

  /**
   * edit user profile
   */
  public editProfile() {
    this.router.navigate([this.router.url, 'edit']);
  }

  /**
   * change password of user
   */
  changePassword() {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '70%',
      id: 'ChangePasswordComponent'
    });
    dialogRef.afterClosed().subscribe();
  }

}
