import { Pipe, PipeTransform } from '@angular/core';
import { TimeDuration } from '@models/enums/time-duration.enum';

@Pipe({
  name: 'timeDuration'
})
export class TimeDurationPipe implements PipeTransform {

  transform(value: string): string {
    return this.getLabel(value);
  }

  private getLabel(value: string) {
    switch (value) {
      case TimeDuration.YEAR: return 'Year';
      case TimeDuration.MONTH: return 'Month';
      case TimeDuration.WEEK: return 'Week';
      case TimeDuration.DAY: return 'Day';
      case TimeDuration.HOUR: return 'Hour';
      case TimeDuration.MINUTE: return 'Minute';
      case TimeDuration.SECOND: return 'Second';
    }
  }

}
