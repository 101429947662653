import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { GoogleChartsModule } from 'angular-google-charts';
import { AlertComponent } from './components/alert/alert.component';
import { AppToolbarComponent } from './components/app-toolbar/app-toolbar.component';
import { AttachmentsComponent } from './components/attachments/attachments.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { PlaceSelectorComponent } from './components/google/map/place-selector/place-selector.component';
import { ShowPlaceMarkerComponent } from './components/google/map/show-place-marker/show-place-marker.component';
import { MenuListItemComponent } from './components/menu-list-item/menu-list-item.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { UserProfileEditComponent } from './components/user-profile/user-profile-edit/user-profile-edit.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { AttachmentsDirective } from './directives/attachments/attachments.directive';
import { AuthGuard } from './guards/auth.guard';
import { AccountGroupTargetPipe } from './pipes/account.group.target/account-group-target.pipe';
import { AttributeGroupPipe } from './pipes/attribute-group.pipe';
import { AverageTimePipe } from './pipes/average.time/average-time.pipe';
import { CommissionTypePipe } from './pipes/commission.type/commission-type.pipe';
import { DayOfWeekPipe } from './pipes/day/of/week/day-of-week.pipe';
import { DisplayTypePipe } from './pipes/display/type/display-type.pipe';
import { EmiratesPipe } from './pipes/emirates.pipe';
import { QuoteStatusPipe } from './pipes/insurance.quote.status/quote-status.pipe';
import { KindTypePipe } from './pipes/kind.type/kind-type.pipe';
import { MeasureTypePipe } from './pipes/measure.type/measure-type.pipe';
import { OrderItemTypePipe } from './pipes/order-item-type/order-item-type.pipe';
import { OrderStatePipe } from './pipes/order-state/order-state.pipe';
import { PosProductTypePipe } from './pipes/pos.product.type/pos-product-type.pipe';
import { ProductTypePipe } from './pipes/product.type/product-type.pipe';
import { ProgramTypePipe } from './pipes/program.type/program-type.pipe';
import { PromoApplicabilityPipe } from './pipes/promo.applicability/promo-applicability.pipe';
import { PromoCodeUsagePipe } from './pipes/promo.code.usage/promo-code-usage.pipe';
import { SearchTypePipe } from './pipes/search.type/search-type.pipe';
import { TagTypePipe } from './pipes/tag.type/tag-type.pipe';
import { TaxComputationTypePipe } from './pipes/tax/computation/type/tax-computation-type.pipe';
import { TaxScopePipe } from './pipes/tax/scope/tax-scope.pipe';
import { TimeConversionPipe } from './pipes/time-conversion/time-conversion.pipe';
import { TimeDurationPipe } from './pipes/time-duration/time-duration.pipe';
import { UserRolePipe } from './pipes/user/role/user-role.pipe';
import { VariantCreationModePipe } from './pipes/variant/creation/mode/variant-creation-mode.pipe';
import { AlertService } from './services/alert/alert.service';

@NgModule({
  declarations: [
    AlertComponent,
    NoDataComponent,
    MenuListItemComponent,
    PageNotFoundComponent,
    UserProfileComponent,
    UserProfileEditComponent,
    PlaceSelectorComponent,
    ShowPlaceMarkerComponent,
    ProductTypePipe,
    TaxComputationTypePipe,
    VariantCreationModePipe,
    DisplayTypePipe,
    DayOfWeekPipe,
    TaxScopePipe,
    UserRolePipe,
    AttachmentsComponent,
    AttachmentsDirective,
    AppToolbarComponent,
    AttributeGroupPipe,
    ProgramTypePipe,
    PromoApplicabilityPipe,
    PromoCodeUsagePipe,
    TagTypePipe,
    UserProfileEditComponent,
    MeasureTypePipe,
    EmiratesPipe,
    SearchTypePipe,
    AverageTimePipe,
    CommissionTypePipe,
    KindTypePipe,
    PosProductTypePipe,
    AccountGroupTargetPipe,
    QuoteStatusPipe,
    ChangePasswordComponent,
    TimeDurationPipe,
    OrderItemTypePipe,
    TimeConversionPipe,
    OrderStatePipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSnackBarModule,
    MatDialogModule,
    MatIconModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSidenavModule,
    MatMenuModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatListModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatSelectModule,
    MatGridListModule,
    MatCheckboxModule,
    MatSelectModule,
    MatRadioModule,
    MatChipsModule,
    MatTabsModule,
    MatStepperModule,
    MatTooltipModule,
    LayoutModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatSlideToggleModule,
    GoogleMapsModule,
    AngularEditorModule,
    GoogleChartsModule.forRoot()
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSnackBarModule,
    MatDialogModule,
    MatIconModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatSidenavModule,
    MatMenuModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatListModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatSelectModule,
    MatGridListModule,
    MatSelectModule,
    MatRadioModule,
    MatChipsModule,
    MatTabsModule,
    MatCheckboxModule,
    MatStepperModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatSlideToggleModule,
    GoogleMapsModule,
    GoogleChartsModule,

    NoDataComponent,
    MenuListItemComponent,
    UserProfileComponent,
    UserProfileEditComponent,
    PlaceSelectorComponent,
    ShowPlaceMarkerComponent,
    AppToolbarComponent,
    AttachmentsComponent,
    ChangePasswordComponent,

    ProductTypePipe,
    TaxComputationTypePipe,
    VariantCreationModePipe,
    DisplayTypePipe,
    DayOfWeekPipe,
    TaxScopePipe,
    UserRolePipe,
    TagTypePipe,
    AttributeGroupPipe,
    MatDatepickerModule,
    MatNativeDateModule,
    MatNativeDateModule,
    ProgramTypePipe,
    PromoApplicabilityPipe,
    PromoCodeUsagePipe,
    MeasureTypePipe,
    SearchTypePipe,
    AverageTimePipe,
    AccountGroupTargetPipe,
    CommissionTypePipe,
    KindTypePipe,
    PosProductTypePipe,
    AngularEditorModule,
    QuoteStatusPipe,
    TimeDurationPipe,
    OrderItemTypePipe,
    TimeConversionPipe,
    OrderStatePipe
  ],
  providers: [
    AlertService,
    AuthGuard,
    TimeConversionPipe,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ]
})
export class SharedModule { }
