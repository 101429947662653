import { Pipe, PipeTransform } from '@angular/core';
import { AttributeGroup } from '../models/enums/attribute-group.enum';

@Pipe({
  name: 'attributeGroup'
})
export class AttributeGroupPipe implements PipeTransform {

  transform(value: string): string {
    return this.getGroup(value);
  }

  /**
   * get the group for corresponding attribute group
   * @param value selected attribute group value
   */
  private getGroup(value: string) {
    switch (value) {
      case AttributeGroup.ALL: return 'All';
      case AttributeGroup.SPECIAL_ATTRIBUTE: return 'Special Attribute';
      case AttributeGroup.SIZE: return 'Size';
      case AttributeGroup.WARRANTY: return 'Warranty';
      case AttributeGroup.TIMELINE: return 'Timeline';
    }
  }

}
