import { Pipe, PipeTransform } from '@angular/core';
import { CommissionType } from 'src/app/shared/models/enums/commission-type.enum';

@Pipe({
  name: 'commissionType'
})
export class CommissionTypePipe implements PipeTransform {

  transform(value: string): string {
    return this.getLabel(value);
  }

  /**
   * get the label for corresponding commission type
   * @param value selected commission type value
   */
  private getLabel(value: string) {
    switch (value) {
      case CommissionType.FIXED: return 'Fixed';
      case CommissionType.PERCENTAGE: return 'Percentage';
      default: return;
    }
  }

}
