import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { AlertService } from '@services/alert/alert.service';
import { LocationModel } from 'src/app/shared/models/location-model';
import { GoogleService } from 'src/app/shared/services/google/google.service';

@Component({
  selector: 'app-show-place-marker',
  templateUrl: './show-place-marker.component.html',
  styleUrls: ['./show-place-marker.component.scss']
})
export class ShowPlaceMarkerComponent implements AfterViewInit {

  @Input() locationModel: LocationModel;
  @Input() isEditable = false;

  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;

  center = { lat: 23.4241, lng: 53.8478 };
  markerOptions = { draggable: false };
  markerPositions: google.maps.LatLngLiteral[] = [];
  zoom = 15;
  display?: google.maps.LatLngLiteral;

  @ViewChild('location') locationInputField: any;
  @ViewChild('map') map: GoogleMap;

  constructor(
    private googleService: GoogleService,
    private alertService: AlertService
  ) { }

  ngAfterViewInit(): void {
    if (!this.hasLocation()) {
      this.setCurrentLocation();
    }
    this.addMarker();
  }

  private hasLocation(): boolean {
    if (this.locationModel && (this.locationModel.lat !== 0 || this.locationModel.lng !== 0)) {
      return true;
    }
    return false;
  }

  onMapClick(event: google.maps.IconMouseEvent) {
    if (this.isEditable) {
      this.locationModel = event.latLng.toJSON();
      this.alertService.setLoadingToolbar(true);
      this.googleService.getAddress(this.locationModel, event.placeId).subscribe(location => {
        this.locationModel = location;
        this.addMarker();
        this.alertService.setLoadingToolbar(false);
      });
    }
  }

  addMarker() {
    if (this.locationModel) {
      this.markerPositions = [];
      this.markerPositions.push(this.locationModel);
      this.center = this.locationModel;
      this.display = this.locationModel;
      this.map.panTo(this.locationModel);
    }
  }

  move(event: google.maps.MouseEvent) {
    this.display = event.latLng.toJSON();
  }

  openInfoWindow(marker: MapMarker) {
    this.infoWindow.open(marker);
  }

  // Get Current Location Coordinates
  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.locationModel = { lat: position.coords.latitude, lng: position.coords.longitude };
        this.addMarker();
      });
    }
  }

}
