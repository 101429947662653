import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceModel } from '@models/device-model';
import { BaseApiService } from '@services/base-api.service';
import { Observable } from 'rxjs';
import { HttpErrorHandler } from 'src/app/network/http-error-handler.service';
import { environment } from 'src/environments/environment';
import { constants } from 'src/utils/constants';

@Injectable({
  providedIn: 'root'
})
export class DeviceService extends BaseApiService<DeviceModel>{

  constructor(
    http: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    super(http, httpErrorHandler);
  }

  public getUrl(): string {
    return environment.api.hostName +
      environment.api.apiFolderPath +
      environment.api.apiVersion +
      '/' +
      constants.endpoints.devices;
  }

  public getInput(model: DeviceModel) {
    return model;
  }

  /**
   * calls #HTTP.POST method to create record
   * @param model record schema to create
   */
  create(model: DeviceModel): Observable<DeviceModel> {
    return this.getHttpClient().post<DeviceModel>(this.getUrl(), this.getInput(model));
  }

}
