// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { versions } from '../utils/versions';

export const environment = {
  production: false,
  version: `${ versions.version }-${ versions.revision }-${ versions.branch }`,
  verDate: `${ versions.lastCommitTime }`,
  api: {
    admin: {
      clientId: 'h7NFGiVMByT35Xv3fll4tQyZChTdJWZuYbrjkiOik24', // alpha
      clientSecret: 'VNuHdFyppHggcj7t4COwIJGMsxbgLnE3MpkVdShJF_A', // alpha
    },
    pos: {
      clientId: 'FBdc6adfPz_EvqxDKfm3Cd1qDOSF_OofLJoNin7Gplo', // alpha
      clientSecret: 'o2qTl5ZaWk5OpLHDnfoagPndLNvigpV2gHoqG4-1eEk', // alpha
    },
    hostName: `http://devapi.mytyre.com/`,
    apiFolderPath: 'api/',
    apiVersion: 'v1',
    imageBaseUrl: 'https://s3.me-south-1.amazonaws.com/cdndevs3.mytyre.com/store/',
    insuranceHostName: `https://insurance.mytyre.com`,
    vehicleHostName: `https://vehicle.mytyre.com/`
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
