import { Pipe, PipeTransform } from '@angular/core';
import { PromoCodeUsage } from 'src/app/shared/models/enums/promo-code-usage.enum';

@Pipe({
  name: 'promoCodeUsage'
})
export class PromoCodeUsagePipe implements PipeTransform {

  transform(value: string): string {
    return this.getLabel(value);
  }

  /**
   *
   * @param value the value to get label
   */
  private getLabel(value: string) {
    switch (value) {
      case PromoCodeUsage.CODE_NEEDED: return 'Code needed';
      default: return 'No code needed';
    }
  }
}
