import { Pipe, PipeTransform } from '@angular/core';
import { ProductType } from '../../models/enums/product-type.enum';

@Pipe({
  name: 'productType'
})
export class ProductTypePipe implements PipeTransform {

  transform(value: string): string {
    return this.getLabel(value);
  }

  /**
   * get the label for corresponding product type
   * @param value selected product type value
   */
  private getLabel(value: string) {
    switch (value) {
      case ProductType.CONSUMABLE_PRODUCT: return 'Consumable Product';
      default: return 'Storable Product';
    }
  }

}
