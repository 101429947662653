<div class="row">
    <mat-form-field appearance="outline" class="full-width">
        <mat-icon matPrefix>search</mat-icon>
        <mat-label>Location</mat-label>
        <input matInput type="text" [(ngModel)]="locationModel.locality" #location name="location">
        <button mat-button type="button" *ngIf="location.value" matSuffix mat-icon-button aria-label="Clear"
            (click)="locationModel.locality = ''">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <button mat-button type="button" *ngIf="location.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="mapPlaceMarker.setCurrentLocation()">
        <mat-icon>my_location</mat-icon>
    </button>
</div>
<app-show-place-marker #mapPlaceMarker [locationModel]="locationModel" [isEditable]="true"></app-show-place-marker>
