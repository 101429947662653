export enum PosProductType {
    PRODUCT_TYPE = 'product_type',
    SERVICE_TYPE = 'service_type',
    TYRE_TYPE = 'tyre_type',
    RECOVERY = 'recovery_type',
    BATTERY = 'battery_type',
    OIL = 'oil_type',
    ACCESSORIES = 'accessories_type',
    SPARE_PARTS = 'spare_parts_type',
    INSURANCE = 'insurance_type',
    ALL = 'all'
}
