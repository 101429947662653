import { Pipe, PipeTransform } from '@angular/core';
import { PromoApplicability } from '@models/enums/promo-applicability.enum';

@Pipe({
  name: 'promoApplicability'
})
export class PromoApplicabilityPipe implements PipeTransform {

  transform(value: string): string {
    return this.getLabel(value);
  }

  /**
   * get the label for corresponding promo applicability
   * @param value selected promo applicability value
   */
  private getLabel(value: string) {
    switch (value) {
      case PromoApplicability.NEXT_ORDER: return 'Next order';
      default: return 'Current order';
    }
  }

}
