import { Pipe, PipeTransform } from '@angular/core';
import { ProgramType } from '@models/enums/program-type.enum';

@Pipe({
  name: 'programType'
})
export class ProgramTypePipe implements PipeTransform {

  transform(value: string): string {
    return this.getLabel(value);
  }

  /**
   * get the label for corresponding program type
   * @param value selected program type value
   */
  private getLabel(value: string) {
    switch (value) {
      case ProgramType.PROMOTION_PROGRAM: return 'Promotion';
      case ProgramType.COMPANY_PROMOTION_PROGRAM: return 'Company Promotion';
      case ProgramType.FREEBIE_PROGRAM: return 'Freebie';
      case ProgramType.USER_PROMOTION_PROGRAM: return 'User Promotion';
      default: return;
    }
  }

}
