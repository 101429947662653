import { Pipe, PipeTransform } from '@angular/core';
import { TaxScope } from 'src/app/shared/models/enums/tax-scope.enum';

@Pipe({
  name: 'taxScope'
})
export class TaxScopePipe implements PipeTransform {

  transform(value: string): string {
    return this.getLabel(value);
  }

  /**
   * get the label for corresponding tax scope
   * @param value selected tax scope value
   */
  private getLabel(value: string) {
    switch (value) {
      case TaxScope.SALE: return 'Sale';
      case TaxScope.PAYMENT: return 'Payment';
      case TaxScope.PURCHASE: return 'Purchase';
      default: return 'For all';
    }
  }

}
