import { Pipe, PipeTransform } from '@angular/core';
import { TagType } from 'src/app/shared/models/enums/tag-type.enum';

@Pipe({
  name: 'tagType'
})
export class TagTypePipe implements PipeTransform {

  transform(value: string): string {
    return this.getLabel(value);
  }

  /**
   * get the label for corresponding pipeline tag type
   * @param value selected pipeline tag type value
   */
  private getLabel(value: string) {
    switch (value) {
      case TagType.BOARD: return 'Board';
      case TagType.LABEL: return 'Label';
    }
  }

}
