<div class="row">
  <div class="col">
    <div class="file-upload-container" *ngIf="isMultiple || files.length==0">
      <input hidden type="file" #fileInput (change)="fileChange($event.target.files)" [accept]="accept">
      <div (click)="fileInput.click()" appAttachment (fileDropped)="fileChange($event)">
        <mat-icon class="upload-icon">add_photo_alternate</mat-icon>
        <p><a>Choose file to upload</a>.
          <mat-error *ngIf="fileTypeError" style=" text-align: center;">File-type not supported.</mat-error>
        </p>
      </div>
    </div>

    <div class="thumbnail-preview-wrapper">
      <div class="thumbnail-container">
        <div class="thumbnail" *ngFor="let file of files;let i= index">
          <img [src]="file.preview" [alt]="file?.file?.name" (click)="onClickImage(file)"
            class="upload-thumbnail-preview">
          <div class="row">
            <button mat-icon-button class="delete-file" (click)="deleteAttachment(i); $event.preventDefault()"
              color="primary">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<mat-spinner *ngIf="isShow"></mat-spinner>
