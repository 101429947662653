import { Pipe, PipeTransform } from '@angular/core';
import { PosProductType } from '../../models/enums/pos-product-type.enum';

@Pipe({
  name: 'posProductType'
})
export class PosProductTypePipe implements PipeTransform {

  transform(value: string): string {
    return this.getLabel(value);
  }

  /**
   * get the label for corresponding product type
   * @param value selected product type value
   */
  private getLabel(value: string) {
    switch (value) {
      case PosProductType.PRODUCT_TYPE: return 'Product Type';
      case PosProductType.SERVICE_TYPE: return 'Service Type';
      case PosProductType.TYRE_TYPE: return 'Tyre Type';
      case PosProductType.RECOVERY: return 'Recovery Type';
      case PosProductType.BATTERY: return 'Battery Type';
      case PosProductType.OIL: return 'Oil Type';
      case PosProductType.ACCESSORIES: return 'Accessories Type';
      case PosProductType.SPARE_PARTS: return 'Spare Parts Type';
      case PosProductType.INSURANCE: return 'Insurance Type';
      default: return 'All';
    }
  }

}
