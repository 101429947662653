import { Pipe, PipeTransform } from '@angular/core';
import { TaxComputationType } from 'src/app/shared/models/enums/tax-computation-type.enum';

@Pipe({
  name: 'taxComputationType'
})
export class TaxComputationTypePipe implements PipeTransform {

  transform(value: string): string {
    return this.getLabel(value);
  }

  /**
   * get the label for corresponding computation type
   * @param value selected computation type value
   */
  private getLabel(value: string) {
    switch (value) {
      case TaxComputationType.ON_GROUPS: return 'On groups';
      case TaxComputationType.FIXED: return 'Fixed';
      case TaxComputationType.PERCENT: return 'Percent';
      case TaxComputationType.ON_PAYMENT: return 'On payment';
      default: return 'Division';
    }
  }

}
