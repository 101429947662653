import { Pipe, PipeTransform } from '@angular/core';
import { DayOfWeek } from 'src/app/shared/models/enums/day-of-week.enum';

@Pipe({
  name: 'dayOfWeek'
})
export class DayOfWeekPipe implements PipeTransform {

  transform(value: string): string {
    return this.getLabel(value);
  }

  /**
   * get the label for corresponding day of week
   * @param value selected day of week value
   */
  private getLabel(value: string) {
    switch (value) {
      case DayOfWeek.SUNDAY: return 'Sunday';
      case DayOfWeek.MONDAY: return 'Monday';
      case DayOfWeek.TUESDAY: return 'Tuesday';
      case DayOfWeek.WEDNESDAY: return 'Wednesday';
      case DayOfWeek.THURSDAY: return 'Thursday';
      case DayOfWeek.FRIDAY: return 'Friday';
      default: return 'Saturday';
    }
  }

}
