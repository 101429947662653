import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { GoogleService } from '@services/google/google.service';
import { LocationModel } from 'src/app/shared/models/location-model';
import { ShowPlaceMarkerComponent } from '../show-place-marker/show-place-marker.component';

@Component({
  selector: 'app-place-selector',
  templateUrl: './place-selector.component.html',
  styleUrls: ['./place-selector.component.scss']
})
export class PlaceSelectorComponent implements AfterViewInit {

  @Input() locationModel: LocationModel = { lat: 0.0, lng: 0.0 };

  @ViewChild('location') locationInputField: any;
  @ViewChild('mapPlaceMarker') mapPlaceMarker: ShowPlaceMarkerComponent;

  constructor(
    private googleService: GoogleService
  ) { }

  ngAfterViewInit(): void {
    this.getPlaceAutocomplete();
    this.googleService.getlastLocation().subscribe(location => {
      this.locationModel = location;
    });
  }

  private getPlaceAutocomplete() {
    if (google) {
      const autocomplete = new google.maps.places.Autocomplete(this.locationInputField.nativeElement,
        {
          types: ['establishment']
        });
      autocomplete.addListener('place_changed', () => {
        this.mapPlaceMarker.locationModel = this.getFormattedAddress(autocomplete.getPlace());
        this.mapPlaceMarker.addMarker();
      });
    }
  }

  /**
   *
   * @param place - Google Autocomplete place object
   * @returns: locationModel - An address object in human readable format
   */
  getFormattedAddress(place: google.maps.places.PlaceResult): LocationModel {
    this.locationModel.locality = place.name;
    if (place.formatted_address) {
      this.locationModel.locality = this.locationModel.locality + ' , ' + place.formatted_address;
    }
    this.locationModel.lat = place.geometry.location.lat();
    this.locationModel.lng = place.geometry.location.lng();
    return this.locationModel;
  }

}
