<!-- Edit User Profile form -->
<form (ngSubmit)="actionSubmit()" #profileEditForm="ngForm">
    <!-- User Profile Edit Mat card -->
    <mat-card>
        <!-- User Profile Edit form header -->
        <mat-card-header>
            <mat-card-title>Edit Profile</mat-card-title>
        </mat-card-header>
        <!-- User Profile Edit form content -->
        <mat-card-content>
            <div class="row">
                <!--Field profile avatar-->
                <div class="col limit-col-width " id="profile-logo">
                    <app-attachments class="template-img-attachment" #attchements isAdd="true"
                        accept="image/x-png,image/gif,image/jpeg,image/jpg" [url]="attachemtEndUrl"
                        [type]="attachemtCategory" [key]="attachemtAttributeKey">
                    </app-attachments>
                </div>
                <div class="col">
                    <!-- Field First Name -->
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>First Name</mat-label>
                        <input matInput placeholder="Enter First Name" [(ngModel)]="userModel.first_name" type="text"
                            name="firstName" #firstName="ngModel" required />
                    </mat-form-field>
                </div>
                <div class="col">
                    <!-- Field Last Name -->
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Last Name</mat-label>
                        <input matInput placeholder="Enter Last Name" [(ngModel)]="userModel.last_name" type="text"
                            name="lastName" #lastName="ngModel" />
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <!-- Field Email-->
                <div class="col">
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Email</mat-label>
                        <input matInput placeholder="Enter Email" [(ngModel)]="userModel.email" type="email"
                            name="email" #email="ngModel" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                        <mat-error *ngIf="email.invalid && email.errors?.required">Email is required</mat-error>
                        <mat-error *ngIf="email.invalid && email.errors?.pattern">Invalid email</mat-error>
                    </mat-form-field>
                </div>

                <!-- Field Phone -->
                <div class="col">
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Phone</mat-label>
                        <input matInput placeholder="Enter Phone number" [(ngModel)]="userModel.phone" type="text"
                            name="phoneNumber" #phoneNumber="ngModel" />
                    </mat-form-field>
                </div>
            </div>

            <!-- Actions Container -->
            <mat-card-actions>
                <button mat-raised-button color="primary" [disabled]="profileEditForm.invalid" type="submit">
                    Submit
                </button>
            </mat-card-actions>

        </mat-card-content>
    </mat-card>
</form>
