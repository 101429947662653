<!-- Form container for change password -->
<form (ngSubmit)="actionSubmit(changePasswordForm)" #changePasswordForm="ngForm">
    <!-- Mat card container for change password -->
    <mat-card>
        <!-- Mat header for change password -->
        <mat-card-header>
            <!-- Mat card title for change password -->
            <mat-card-title> Change Password </mat-card-title>
        </mat-card-header>
        <!-- Mat card content -->
        <mat-card-content class="mat-content-center">
            <!--TODO: form field for showing current password of user which is to be done in later
                <div class="row"> -->
            <!-- Form field for current password -->
            <!--TODO: <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Current Password</mat-label>
                    <input matInput placeholder="Enter Current Password" required>
                    <mat-error>Current Password is required</mat-error>
                </mat-form-field> -->
            <!--TODO: </div> -->

            <div class="row">
                <!-- Form field for new password -->
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>New Password</mat-label>
                    <input matInput placeholder="Enter New Password" [type]="passwordViewToggle ? 'password' : 'text'"
                        minlength="6" [(ngModel)]="userModel.password" name="password" #password="ngModel" required>
                    <mat-error *ngIf="password.invalid && password.errors?.required">New Password is required
                    </mat-error>
                    <mat-error *ngIf="password.invalid && password.errors?.minlength">Invalid password</mat-error>
                    <button type="button" mat-icon-button matSuffix (click)="passwordViewToggle = !passwordViewToggle"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordViewToggle">
                        <mat-icon>{{passwordViewToggle ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <div class="row">
                <!-- Form field for confirm password -->
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Confirmation Password</mat-label>
                    <input matInput placeholder="Enter Confirmation Password"
                        [type]="passwordViewToggle ? 'password' : 'text'" minlength="6"
                        [(ngModel)]="userModel.confirmPassword" name="confirmPassword" #confirmPassword="ngModel"
                        required>
                    <mat-error *ngIf="password.invalid && password.errors?.required">Confirmation Password is required
                    </mat-error>
                    <mat-error *ngIf="password.invalid && password.errors?.minlength">Invalid password</mat-error>
                    <button type="button" mat-icon-button matSuffix (click)="passwordViewToggle = !passwordViewToggle"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordViewToggle">
                        <mat-icon>{{passwordViewToggle ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>
            </div>
        </mat-card-content>
        <!-- Actions container for the form -->
        <mat-card-actions>
            <!-- Form submit action -->
            <button mat-raised-button color='primary' [disabled]="changePasswordForm.invalid"
                type="submit">Update</button>
        </mat-card-actions>
    </mat-card>
</form>
