import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataService } from '@services/data/data.service';
import { catchError } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/network/http-error-handler.service';
import { environment } from 'src/environments/environment';
import { constants } from 'src/utils/constants';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {

  private handleError: HandleError;

  constructor(
    private http: HttpClient, httpErrorHandler: HttpErrorHandler, private dataService: DataService
  ) { this.handleError = httpErrorHandler.createHandleError(); }

  public getUrl(): string {
    return environment.api.hostName +
      environment.api.apiFolderPath +
      environment.api.apiVersion +
      this.dataService.getScope();
  }

  /**
   * get user profile url
   */
  public userUrl(): string {
    return environment.api.hostName +
      environment.api.apiFolderPath +
      environment.api.apiVersion +
      constants.endpoints.profile;
  }

  /**
   * @param files array of File
   * @param url endpoint
   */
  public upload(key: string, url: string, type: string, files: File[], attachemtId: number = null) {
    const formData = new FormData();

    try {
      if (url === '/profile') {
        if (files) {
          files.forEach((value) => {
            formData.append(`${ key }[avatar]`, value);
          });
        }
        return this.http.put(this.userUrl(), formData).pipe(
          catchError(this.handleError())
        );
      }
      // condition for implementing new way of image upload
      else if (url.includes('update_badge')) {
        if (files) {
          files.forEach((value) => {
            formData.append(`${ key }`, value);
            if (attachemtId) {
              formData.append(`${ key }[id]`, attachemtId.toString());
            }
          });
        }
        return this.http.put(this.getUrl() + url, formData).pipe(
          catchError(this.handleError())
        );
      }

      else {
        if (files) {
          files.forEach((value, i) => {
            formData.append(`${ key }[attachments_attributes][${ i }][attachment]`, value);
            formData.append(`${ key }[attachments_attributes][${ i }][category]`, type);
            if (attachemtId) {
              formData.append(`${ key }[attachments_attributes][${ i }][id]`, attachemtId.toString());
            }
          });
        }
        return this.http.put(this.getUrl() + url, formData).pipe(
          catchError(this.handleError())
        );
      }
    } catch (error) {
      throw error;
    }
  }

  deleteAttachment(key: string, url: string, attachemtId: number = null) {
    const formData = new FormData();
    formData.append(`${ key }[attachments_attributes][][_destroy]`, 'true');
    formData.append(`${ key }[attachments_attributes][][id]`, attachemtId.toString());
    return this.http.put(this.getUrl() + url, formData).pipe(
      catchError(this.handleError())
    );
  }
}
