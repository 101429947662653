import { Injectable } from '@angular/core';
import { LocationModel } from '@models/location-model';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoogleService {

  private locationModel = new Subject<LocationModel>();
  private geocoder = new google.maps.Geocoder();

  getAddress(locationModel: LocationModel, placeId?: string): Observable<LocationModel> {
    if (placeId) {
      return this.geocodePlaceId(locationModel, placeId);
    } else {
      return this.geocodeLatLng(locationModel);
    }
  }

  getlastLocation(): Observable<LocationModel> {
    return this.locationModel;
  }

  private geocodeLatLng(
    locationModel: LocationModel
  ): Observable<LocationModel> {
    this.geocoder.geocode(
      { location: locationModel },
      (
        results: google.maps.GeocoderResult[],
        status: google.maps.GeocoderStatus
      ) => {
        if (status === 'OK') {
          if (results[0]) {
            locationModel.locality = results[0].formatted_address;
            this.locationModel.next(locationModel);
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      }
    );
    return this.locationModel;
  }

  private geocodePlaceId(
    locationModel: LocationModel,
    placeId?: string
  ): Observable<LocationModel> {
    this.geocoder.geocode(
      { placeId },
      (
        results: google.maps.GeocoderResult[],
        status: google.maps.GeocoderStatus
      ) => {
        if (status === 'OK') {
          if (results[0]) {
            locationModel.locality = results[0].formatted_address;
            this.locationModel.next(locationModel);
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      }
    );
    return this.locationModel;
  }

}
