import { Component, ViewChild, AfterViewInit } from '@angular/core';

import { UserModel } from 'src/app/shared/models/user-model';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { AlertService } from '@services/alert/alert.service';
import { Location } from '@angular/common';
import { AttachmentsComponent } from '@components/attachments/attachments.component';
import { constants } from 'src/utils/constants';
import { AttachmentModel } from '@models/attachment-model';
import { AttachmentCategory } from '@models/enums/attachment-category.enum';
import { AlertActionModel } from '@models/alert-action-model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-profile-edit',
  templateUrl: './user-profile-edit.component.html',
  styleUrls: ['./user-profile-edit.component.scss']
})
export class UserProfileEditComponent implements AfterViewInit {

  imageBaseUrl = environment.api.imageBaseUrl;
  userModel: UserModel = {};
  @ViewChild('attchements') attchements: AttachmentsComponent;
  attachemtEndUrl = constants.endpoints.profile;
  attachemtAttributeKey = 'user';
  attachemtCategory = AttachmentCategory.LOGO;
  logo: AttachmentModel;

  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    private location: Location
  ) { }

  ngAfterViewInit(): void {
    this.getUserDetails();
  }

  /**
   * Get User Details
   */
  getUserDetails() {
    this.alertService.setLoadingToolbar(true);
    this.authService.getMe().subscribe(res => {
      this.alertService.setLoadingToolbar(false);
      this.userModel = res;
      if (this.userModel.avatar_data) {
        this.logo = this.userModel.avatar_data;
        if (this.logo) {
          this.attchements.addFile({ preview: `${ environment.api.imageBaseUrl }${ this.logo.id }` });
        }
      }
    });
  }

  /**
   * To edit the user profile details
   */
  public actionSubmit() {
    this.alertService.setLoading(true);
    this.authService.updateMe(this.userModel).subscribe(res => {
      this.alertService.setLoading(false);
      if (res) {
        this.addAvatar();
        this.alertService.success('Profile Updated Successfully');
        this.alertService.getAction('ProfileUpdatedSuccessfully').subscribe((alertActionModel: AlertActionModel) => {
          if (alertActionModel.actionId === 1 && alertActionModel.functionName === 'ProfileUpdatedSuccessfully') {
            this.authService.setForce();
            this.actionBack();
          }
        });
      }
    });
  }

  /**
   * add user profile avatar
   */
  addAvatar() {
    this.attchements.uploadFile(null, this.logo?.id); // submit attachments
  }

  /**
   * action to navigate back
   */
  private actionBack() {
    this.location.back();
  }

}
