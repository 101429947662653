import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AlertService } from 'src/app/shared/services/alert/alert.service';

/** Type of the handleError function returned by HttpErrorHandler.createHandleError */
export type HandleError =
  <T> () => (error: HttpErrorResponse) => Observable<T>;

/** Handles HttpClient errors */
@Injectable()
export class HttpErrorHandler {

  constructor(private messageService: AlertService) { }

  /** Create curried handleError function that already knows the service name */
  createHandleError = () => <T>() => this.handleError<T>();

  /**
   * Returns a function that handles Http operation failures.
   * This error handler lets the app continue to run as if no error occurred.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  handleError<T>() {

    return (error: HttpErrorResponse): Observable<T> => {
      if (error.status === 200) {
        return null;
      }
      let message = (error.error instanceof ErrorEvent) ?
        error.error.message : (error.error) ? error.error.error_description : 'Unknown Error';

      if (error.error instanceof ProgressEvent) {
        message = `${ error.statusText } , Please contact administrator!`;
      }

      if (error.status === 422) {
        message = this.getErrorMessage(error.error);
      }

      if (!message) {
        message = error.error.error;
      }
      if (error instanceof Error) {
        message = error.error;
      }

      if (message) {
        this.messageService.error(`${ message }`);
        return of(error.error as T);
      }
      // Let the app keep running by returning a safe result.
      return of(error.error as T);
    };

  }

  private getErrorMessage(errorObject: any): string {
    let error: any = errorObject;
    let message = '';
    if (errorObject.errors) {
      error = errorObject.errors;
    }
    if (error) {
      const keysElements = Object.keys(error);
      keysElements.forEach(item => {
        if (error[item]) {
          const messages: Array<string> = error[item];
          const value = ((previousValue: string, currentValue: string) => {
            previousValue = previousValue + currentValue; return previousValue;
          });
          if (messages instanceof Array) {
            message = item + messages.reduce(value, ' ');
          } else {
            message = messages;
          }

        }
      });
    }
    return message;
  }

}
