import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { constants } from 'src/utils/constants';
import { AuthResponseModel } from '../../auth/models/auth-response-model';
import { AuthScope } from '../models/enums/auth-scope.enum';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(public router: Router) { }

    // tslint:disable-next-line: variable-name
    canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const url: string = state.url;
        const host = window.location.host;
        if ((host.endsWith('4201') || host.includes('admin')) && (!url.startsWith('/admin'))) {
            this.router.navigateByUrl('/admin');
            return false;
        }
        if ((host.endsWith('4202') || host.includes('partner')) && (!url.startsWith('/pos'))) {
            this.router.navigateByUrl('/pos');
            return false;
        }
        return this.checkLogin(url);
    }

    checkLogin(url: string): boolean {
        const authResponse: AuthResponseModel = JSON.parse(localStorage.getItem(constants.localStorage.authResponse));
        const accountId: number = +JSON.parse(localStorage.getItem(constants.localStorage.accountId));
        if (authResponse && authResponse.access_token && accountId > 0) {
            if (url.startsWith('/admin')) {
                if (authResponse.scope === AuthScope.SUPER_ADMIN.toLocaleLowerCase()) {
                    return true;
                } else {
                    this.navigateToLogin('admin');
                    return false;
                }
            } else if (url.startsWith('/pos')) {
                if (authResponse.scope === AuthScope.BUSINESS_PARTNER.toLocaleLowerCase()) {
                    return true;
                } else {
                    this.navigateToLogin('pos');
                    return false;
                }
            } else {
                return true;
            }
        } else {
            if (url.startsWith('/admin')) {
                this.navigateToLogin('admin');
            } else {
                this.navigateToLogin('pos');
            }
            return false;
        }
    }

    navigateToLogin(scope: string) {
        this.router.navigate([scope, 'auth']);
    }
}
