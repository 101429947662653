import { Pipe, PipeTransform } from '@angular/core';
import { VariantCreationMode } from 'src/app/shared/models/enums/variant-creation-mode.enum';

@Pipe({
  name: 'variantCreationMode'
})
export class VariantCreationModePipe implements PipeTransform {

  transform(value: string): string {
    return this.getLabel(value);
  }

  /**
   * get the label for corresponding creation mode
   * @param value selected creation mode value
   */
  private getLabel(value: string) {
    switch (value) {
      case VariantCreationMode.NO_VARIANT: return 'No Variant';
      case VariantCreationMode.DYNAMIC: return 'Dynamic';
    }
  }

}
