import { Pipe, PipeTransform } from '@angular/core';
import { AverageTime } from '@models/enums/average-time.enum';

@Pipe({
  name: 'averageTime'
})
export class AverageTimePipe implements PipeTransform {

  transform(value: string): string {
    return this.getAverageTime(value);
  }

  /**
   * get the Average Time
   * @param value selected average time
   */
  private getAverageTime(value: string) {
    switch (value) {
      case AverageTime.MINUTES_15: return '15 Minutes';
      case AverageTime.MINUTES_30: return '30 Minutes';
      case AverageTime.MINUTES_45: return '45 Minutes';
      case AverageTime.HOUR_1: return '1 Hour';
      case AverageTime.HOUR_QUARTER: return '1 Hour 15 Minutes';
      case AverageTime.HOUR_HALF: return '1 Hour 30 Minutes';
    }
  }

}
