import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertActionModel } from '@models/alert-action-model';
import { UserModel } from '@models/user-model';
import { AlertService } from '@services/alert/alert.service';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  userModel: UserModel = {};
  passwordViewToggle = true;
  dialogRef: MatDialogRef<ChangePasswordComponent>;

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.dialogRef = this.dialog.getDialogById('ChangePasswordComponent');
  }

  /**
   * action submit function
   * @param change password form
   */
  actionSubmit(changePasswordForm: NgForm) {
    if (this.userModel.password === this.userModel.confirmPassword) {
      this.authService.changePassword(this.userModel.password).subscribe(res => {
        if (res) {
          this.alertService.success(`Password Changed Successfully`,
            'PasswordChangeSuccess',
            'OK');
          const alertListener = this.alertService.getAction('PasswordChangeSuccess').subscribe((alertActionModel: AlertActionModel) => {
            if (alertActionModel.actionId === 1 && alertActionModel.functionName === 'PasswordChangeSuccess') {
              alertListener.unsubscribe();
              if (this.dialogRef) {
                this.dialogRef.close();
              }
            }
          });
        }
      });
    } else {
      this.alertService.warn(`Your password and confirmation password do not match`, 'PasswordChangeFailure', 'OK');
      this.alertService.getAction('PasswordChangeFailure').subscribe((alertActionModel: AlertActionModel) => {
        if (alertActionModel.actionId === 1 && alertActionModel.functionName === 'PasswordChangeFailure') {
          changePasswordForm.resetForm();
        }
      });
    }
  }

}
