import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from 'src/app/network/http-error-handler.service';
import { constants } from 'src/utils/constants';
import { BaseModel } from '../models/base-model';
import { SearchType } from '../models/enums/search-type.enum';
import { PagedResponseModel } from '../models/paged-response-model';
import { SearchModel } from '../models/search-model';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseApiService<DataModelType extends BaseModel> {

  private isForce = false;
  private skipAuthToken = false;
  private isStrapi = false;
  private strapiTotalCount: number;
  private handleError: HandleError;
  private strapiCountApiParams = '';
  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError();
  }

  /**
   * getUrl
   */
  public abstract getUrl(): string;

  /**
   * transform the given model to the type needed for API body input
   * @param model model to transform as input object
   */
  public abstract getInput(model: DataModelType): any;

  /**
   * getHttpClient
   */
  public getHttpClient(): HttpClient {
    return this.http;
  }

  /**
   * getErrorHandler
   */
  public getErrorHandler() {
    return this.handleError<DataModelType>();
  }
  /**
   * addOrUpdate
   */
  public addOrUpdate(model: DataModelType) {
    if (model.id) {
      return this.update(model);
    } else {
      return this.create(model);
    }
  }

  /**
   * calls #HTTP.POST method to create record
   * @param model record schema to create
   */
  create(model: DataModelType): Observable<DataModelType> {
    return this.http.post<DataModelType>(this.getUrl(), this.getInput(model)).pipe(
      catchError(this.handleError<DataModelType>())
    );
  }

  /**
   * get all
   */
  getAll(sortField?: string, sortDirection?: string, pageIndex?: number, pageSize?: number, searchModels?: SearchModel[]):
    Observable<any[] | PagedResponseModel<DataModelType[]>> {
    let httpHeader = new HttpHeaders();
    let params = new HttpParams();
    if (this.isForce) {
      httpHeader = httpHeader.append('x-refresh', this.isForce.toString());
    }
    if (this.skipAuthToken) {
      httpHeader = httpHeader.append('x-skip-auth-token', this.skipAuthToken.toString());
    }
    if (pageIndex) {
      if (this.isStrapi) {
        params = params.append('_start', this.getStartPoint(pageIndex, pageSize));
      } else {
        params = params.append('page', (pageIndex + 1).toString());
      }
    }
    if (pageSize) {
      if (this.isStrapi) {
        params = params.append('_limit', pageSize.toString());
      } else {
        params = params.append('items', pageSize.toString());
      }
    }
    this.strapiCountApiParams = '';
    if (this.isStrapi && searchModels && searchModels.length) {
      searchModels.forEach(searchModel => {
        if (searchModel && searchModel.query && searchModel.columnMap) {
          searchModel.columnMap.forEach((value: string, key: SearchType) => {
            this.strapiCountApiParams = `${ value }${ key }=${ searchModel.query }`;
            params = params.append(`${ value }${ key }`, searchModel.query);
          });
        }
      });
    }
    if (searchModels && !this.isStrapi) {
      searchModels.forEach(searchModel => {
        if (searchModel && searchModel.query && searchModel.columnMap) {
          searchModel.columnMap.forEach((value: string, key: SearchType) => {
            params = params.append(`query[${ value }${ key }]${ searchModel.isArray ? '[]' : '' }`, searchModel.query);
          });
        }
        if (searchModel && searchModel.query && searchModel.columnMapForFilter) {
          searchModel.columnMapForFilter.forEach((value: string, key: string) => {
            params = params.append(`query[${ value }${ key }]${ searchModel.isArray ? '[]' : '' }`, searchModel.query);
          });
        }
      });
    }
    if (sortField) {
      if (this.isStrapi) {
        params = params.append(`_sort`, `${ sortField }:${ sortDirection ? sortDirection : 'asc' }`);
      } else {
        params = params.append(`query[s]`, `${ sortField }+${ sortDirection ? sortDirection : 'asc' }`);
      }
    }

    if (this.isStrapi) {
      this.getCountStrapi(this.strapiCountApiParams);
    }

    return this.http.get<DataModelType[]>(this.getUrl(), { headers: httpHeader, observe: 'response', params }).pipe(
      map(
        data => {
          this.isForce = false;
          const pagedResponseModel: PagedResponseModel<DataModelType[]> = {};
          pagedResponseModel.totalCount = (this.isStrapi) ? this.strapiTotalCount : +data.headers.get('Total-Count');
          pagedResponseModel.totalPages = +data.headers.get('Total-Pages');
          pagedResponseModel.pageItems = +data.headers.get('Page-Items');
          pagedResponseModel.pageItems = +data.headers.get('Current-Page');
          pagedResponseModel.data = data.body;
          return pagedResponseModel;
        }
      ),
      catchError(this.handleError<DataModelType[]>())
    );
  }

  /**
   * function to get total count of items from strapi api
   */
  async getCountStrapi(params = ''): Promise<number> {
    let httpHeader = new HttpHeaders();
    let url = `${ this.getUrl() }${ constants.endpoints.count }`;
    if (params) {
      url = `${ this.getUrl() }${ constants.endpoints.count }?${ params }`;
    }
    httpHeader = httpHeader.append('x-skip-auth-token', 'true');

    // if (typeof this.strapiTotalCount === 'undefined') {
    // save result
    this.strapiTotalCount = await this.http.get(url, { headers: httpHeader })
      .toPromise()
      .then(resp => resp as number);
    // }
    return this.strapiTotalCount;
  }

  /**
   * get the start point for strapi pagenation
   * @param pageIndex current page index
   * @param pageSize items per page
   */
  getStartPoint(pageIndex: number, pageSize: number): string {
    return (pageIndex * pageSize).toString();
  }

  /**
   * Get
   */
  getByStringId(id: string): Observable<DataModelType> {
    const url = `${ this.getUrl() }/${ id }`;
    let params = new HttpParams();
    let httpHeader = new HttpHeaders();
    if (this.isForce) {
      params = params.append('force', this.isForce.toString());
      httpHeader = httpHeader.append('x-refresh', this.isForce.toString());
    }
    if (this.skipAuthToken) {
      httpHeader = httpHeader.append('x-skip-auth-token', this.skipAuthToken.toString());
    }
    return this.http.get<DataModelType>(url, { headers: httpHeader, params }).pipe(
      catchError(this.handleError<DataModelType>())
    );
  }

  /**
   * Get
   */
  get(id: number): Observable<DataModelType> {
    return this.getByStringId(id?.toString());
  }

  /**
   * calls #HTTP.PUT method to update record
   * @param model record schema to update
   */
  update(model: DataModelType): Observable<DataModelType> {
    const url = `${ this.getUrl() }/${ model.id }`;
    return this.http.put<DataModelType>(url, this.getInput(model)).pipe(
      catchError(this.handleError<DataModelType>())
    );
  }

  /**
   * delete the data with given id
   * @param id plan id to delete
   */
  delete(id: number): Observable<DataModelType | any> {
    const url = `${ this.getUrl() }/${ id }`;
    return this.http.delete<DataModelType>(url).pipe(
      catchError(this.handleError<DataModelType>())
    );
  }

  /**
   * Function to set the is force flag.
   * @param value is force flag
   */
  setForce(value: boolean = true) {
    this.isForce = value;
  }

  /**
   * Function to set the is skip auth token flag.
   * @param value is force flag
   */
  setSkipAuthToken(value: boolean = true) {
    this.skipAuthToken = value;
  }

  /**
   * Function to set the is is strapi flag.
   * @param value is force flag
   */
  setStrapi(value: boolean = true) {
    this.isStrapi = value;
  }

}
