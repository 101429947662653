import { Pipe, PipeTransform } from '@angular/core';
import { InsuranceQuoteStatus } from '@models/enums/insurance-quote-status.enum';

@Pipe({
  name: 'quoteStatus'
})
export class QuoteStatusPipe implements PipeTransform {

  transform(value: string): string {
    return this.getStatus(value);
  }

  /**
   * get the status for corresponding insurance quote status
   * @param value selected insurance quote status value
   */
  private getStatus(value: string) {
    switch (value) {
      case InsuranceQuoteStatus.OPEN: return 'Open';
      case InsuranceQuoteStatus.CLOSED: return 'Closed';
      case InsuranceQuoteStatus.PROCESSING: return 'Processing';
    }
  }

}
