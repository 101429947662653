<mat-card class="detail-card">
    <mat-card-header>
        <mat-card-title>{{title}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-icon aria-hidden="false" aria-label="Example home icon">{{icon}}</mat-icon>
        <p>{{message}} </p>
        <button *ngIf="showAction" mat-raised-button color="primary"
            (click)="action.emit('action')">{{actionText}}</button>
    </mat-card-content>
</mat-card>