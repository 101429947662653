import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatus } from '@models/enums/order-status.enum';

@Pipe({
  name: 'orderState'
})
export class OrderStatePipe implements PipeTransform {

  transform(value: string): string {
    return this.getLabel(value);
  }

  private getLabel(value: string) {
    switch (value) {
      case OrderStatus.ARRIVED: return 'Arrived';
      case OrderStatus.DELIVERED: return 'Delivered';
      case OrderStatus.DRAFT: return 'Draft';
      case OrderStatus.ORDERED: return 'Ordered';
      case OrderStatus.PROCESSING: return 'Processing';
      case OrderStatus.CANCELLED: return 'Cancelled';
      case OrderStatus.PENDING: return 'Pending';
      case OrderStatus.ACCEPTED: return 'Accepted';
    }
  }

}
