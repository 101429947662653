import { Pipe, PipeTransform } from '@angular/core';
import { MeasureType } from '@models/enums/measure-type.enum';

@Pipe({
  name: 'measureType'
})
export class MeasureTypePipe implements PipeTransform {

  transform(value: string): string {
    return this.getLabel(value);
  }

  /**
   * get the label for corresponding measure type
   * @param value selected measure type value
   */
  private getLabel(value: string) {
    switch (value) {
      case MeasureType.WORKING_TIME: return 'Working time';
      case MeasureType.WEIGHT: return 'Weight';
      case MeasureType.VOLUME: return 'Volume';
      case MeasureType.DISTANCE: return 'Distance';
      default: return 'Length';
    }
  }
}
