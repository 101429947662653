import { Pipe, PipeTransform } from '@angular/core';
import { AccountGroupTarget } from '@models/enums/account-group-target.enum';

@Pipe({
  name: 'accountGroupTarget'
})
export class AccountGroupTargetPipe implements PipeTransform {

  transform(value: string): string {
    return this.getAccountGroupTarget(value);
  }

  /**
   * get the Account Group Target
   * @param value selected Account Group Target
   */
  private getAccountGroupTarget(value: string) {
    switch (value) {
      case AccountGroupTarget.ASSET: return 'Asset';
      case AccountGroupTarget.LIABILITY: return 'Liability';
      case AccountGroupTarget.PROFIT_AND_LOSS: return 'Profit and Loss';
    }
  }

}
