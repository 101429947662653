import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { DeviceModel } from 'src/app/shared/models/device-model';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { DeviceService } from 'src/app/shared/services/devices/device.service';

@Injectable({
    providedIn: 'root'
})
export class MessagingService {

    deviceModel: DeviceModel = {};
    currentMessage = new BehaviorSubject(null);
    pushNotificationToken: string = localStorage.getItem('pushToken');

    constructor(
        private angularFireAuth: AngularFireAuth,
        private angularFireMessaging: AngularFireMessaging,
        private deviceService: DeviceService,
        private alertService: AlertService

    ) { }

    /**
     * update token in  database
     *
     * @param token string as a value
     */
    updateToken(token: string) {

        this.angularFireAuth.authState.pipe(take(1)).subscribe(
            () => {
                const userAgent: string = window.navigator.userAgent;
                this.deviceModel.token = token;
                this.deviceModel.platform = 'Web';
                this.deviceModel.model = userAgent;
                this.deviceService.create(this.deviceModel).subscribe(res => {
                    this.alertService.setLoading(false);
                    if (res.id && res.token) {
                        localStorage.setItem('pushToken', res.token);
                    }
                });
            });
    }

    /**
     * request permission for notification from firebase cloud messaging
     *
     */
    requestPermission() {
        this.angularFireMessaging.requestToken.subscribe(
            (token) => {
                console.log(token);
                if (!this.pushNotificationToken || this.pushNotificationToken !== token) {
                    this.updateToken(token);
                }
            },
            (err) => {
                console.error('Unable to get permission to notify.', err);
            }
        );
    }

    /**
     * hook method when new notification received in foreground
     */
    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload) => {
                this.currentMessage.next(payload);
            });
    }

}
