export enum Emirates {
    ABU_DHABI = 'Abu Dhabi',
    AJMAN = 'Ajman',
    DUBAI = 'Dubai',
    FUJAIRAH = 'Fujairah',
    RAS_AL_KHAIMAH = 'Ras al-Khaimah',
    SHARJAH = 'Sharjah',
    UMM_AL_QUWAIN = 'Umm al-Quwain',
    UAE = 'UAE'
}
