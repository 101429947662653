export enum OrderStatus {
    DRAFT = 'draft',
    DELIVERED = 'delivered',
    ORDERED = 'ordered',
    PROCESSING = 'processing',
    CANCELLED = 'cancelled',
    PENDING = 'pending',
    ARRIVED = 'arrived',
    ACCEPTED = 'accepted'
}
