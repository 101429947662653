import { Pipe, PipeTransform } from '@angular/core';
import { KindType } from 'src/app/shared/models/enums/kind-type.enum';

@Pipe({
  name: 'kindType'
})
export class KindTypePipe implements PipeTransform {

  transform(value: string): string {
    return this.getLabel(value);
  }

  /**
   * get the label for corresponding kind type
   * @param value selected kind type value
   */
  private getLabel(value: string) {
    switch (value) {
      case KindType.ASSET: return 'Asset';
      case KindType.CREDITOR: return 'Creditor';
      case KindType.DEBTOR: return 'Debtor';
      case KindType.EXPENSE: return 'Expense';
      case KindType.FORWARD: return 'Forward';
      case KindType.LIABILITY: return 'Liability';
      case KindType.REVENUE: return 'Revenue';
      default: return;
    }
  }

}
