import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { NotificationModel } from '@models/notification-model';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { MessagingService } from '../../messaging.service';
import { AlertService } from '../../services/alert/alert.service';
import { AuthService } from '../../services/auth/auth.service';
import { DataService } from '../../services/data/data.service';

@Component({
  selector: 'app-app-toolbar',
  templateUrl: './app-toolbar.component.html',
  styleUrls: ['./app-toolbar.component.scss']
})
export class AppToolbarComponent implements OnInit {

  @Input() matSidenav: MatSidenav;
  loading: Observable<boolean> = this.alertService.getLoadingToolbar();
  message: BehaviorSubject<NotificationModel>;

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private dataService: DataService,
    private router: Router,
    private messagingService: MessagingService
  ) { }

  ngOnInit() {
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage;
    this.message.subscribe(notificationModel => {
      if (notificationModel) {
        this.alertService.success(notificationModel.notification.body, 'Close', 'Ok', 'Yes', true);
      }
    });
  }

  /**
   * actionProfile
   */
  public actionProfile() {
    this.router.navigateByUrl(`${ this.dataService.getScope() }/user/profile`);
  }
  /**
   * logout
   */
  public logout() {
    this.authService.logout().toPromise();
    localStorage.clear();
    this.router.navigateByUrl(`${ this.dataService.getScope() }/auth/login`);
  }

}
