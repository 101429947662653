<mat-toolbar color="primary">
    <!-- Drawer toggle Menu -->
    <button mat-icon-button (click)="matSidenav?.toggle()">
        <mat-icon>menu</mat-icon>
    </button>
    <!-- App Name -->
    <h1 class="app-app-name"><img src="../../../../assets/logo.png" class="header-logo"></h1>
    <!-- Spacer between App name and Option Menu -->
    <span class="spacer"></span>
    <!-- Option menu button -->
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="User profile menu">
        <mat-icon>account_circle</mat-icon>
    </button>
    <!-- Option Menu Items -->
    <mat-menu #menu="matMenu">
        <button type="button" (click)="actionProfile()" mat-menu-item>Profile</button>
        <button type="button" (click)="logout()" mat-menu-item>Logout</button>
    </mat-menu>

</mat-toolbar>
<!-- Common Progress bar -->
<mat-progress-bar *ngIf="(loading | async)" mode="indeterminate"></mat-progress-bar>
