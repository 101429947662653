import { Pipe, PipeTransform } from '@angular/core';
import { SearchType } from '@models/enums/search-type.enum';

@Pipe({
  name: 'searchType'
})
export class SearchTypePipe implements PipeTransform {

  transform(value: string): string {
    return this.getLabel(value);
  }

  /**
   * get the label for corresponding pipeline tag type
   * @param value selected pipeline tag type value
   */
  private getLabel(value: string) {
    switch (value) {
      case SearchType.EQUAL: return '=';
      case SearchType.NOT_EQUAL: return '!=';
      case SearchType.CONTAINS_ANY_OF_VALUES_WITH_CASE_INSENSITIVE: return 'Contains Any';
    }
  }

}
