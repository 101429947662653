import { Injectable } from '@angular/core';
import { ProductTemplateModel } from '@models/product-template-model';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  productTemplateModels: ProductTemplateModel[] = [];
  private scope = '/admin';

  getScope(): string {
    return this.scope;
  }

  setScope(scope: string) {
    this.scope = scope;
  }

  /**
   * Set product templates
   * @param productTemplateModels template models
   */
  public setProductTemplate(productTemplateModels: ProductTemplateModel[]) {
    this.productTemplateModels = productTemplateModels;
  }

  /**
   * Get product templates
   */
  public getProductTemplateModels() {
    return this.productTemplateModels;
  }

}
