import {
  HttpErrorResponse, HttpHandler,
  HttpHeaderResponse, HttpHeaders, HttpInterceptor,
  HttpProgressEvent, HttpRequest,
  HttpResponse, HttpSentEvent,
  HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/auth/models/auth-response-model';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { constants } from 'src/utils/constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private auth: AuthService,
    private router: Router
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {

    return next.handle(this.addTokenToRequest(request, this.auth.getAuthorizationToken()))
      .pipe(
        catchError(err => {
          if (err instanceof HttpErrorResponse) {
            switch ((err as HttpErrorResponse).status) {
              case 401:
                if (request.url.endsWith(constants.endpoints.auth)) {
                  return throwError(err);
                }
                const expiresTime = +localStorage.getItem(constants.localStorage.tokenExpiresTime);
                if (expiresTime > new Date().getTime()) {
                  this.logout();
                  return throwError(err);
                }
                return this.handle401Error(request, next);
              default:
                return throwError(err);
            }
          } else {
            return throwError(err);
          }
        }));
  }

  private logout() {
    const scope = this.router.url.split('/')[1];
    this.router.navigate([scope, 'auth', 'login'], { queryParams: { redirectUrl: this.router.url } });
  }

  private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    if (request.headers.get('x-skip-auth-token')) {
      return request.clone({ headers: new HttpHeaders() });
    } else {
      if (request.body instanceof FormData) {
        return request.clone({
          setHeaders: {
            Authorization: token
          }
        });
      } else {
        return request.clone({
          setHeaders: {
            Authorization: token,
            'Content-Type': 'application/json',
          }
        });
      }
    }
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): any {
    const authResponse = JSON.parse(localStorage.getItem(constants.localStorage.authResponse));
    if (authResponse) {
      const promise: Promise<AuthResponseModel> = this.auth.refreshToken(authResponse).toPromise<AuthResponseModel>();
      promise.then(res => {
        if (res) {
          const expireTime = (res.created_at + res.expires_in) * 1000;
          localStorage.setItem(constants.localStorage.tokenExpiresTime, expireTime.toString());
          localStorage.setItem(constants.localStorage.authResponse, JSON.stringify(res));
          return next.handle(this.addTokenToRequest(request, res.access_token));
        } else {
          return this.logout() as any;
        }
      });
      promise.catch(() => {
        return this.logout() as any;
      });
    }
  }
}
