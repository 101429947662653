export enum AttachmentCategory {
    LOGO = 'logo',
    IMAGE_GALLERY_PRIMARY = 'image_gallery_primary',
    VIDEO_GALLERY_PRIMARY = 'video_gallery_primary',
    DOCUMENT_PROOF = 'document_proof',
    ICON = 'icon',
    BANNER = 'banner',
    STATUS_BADGE = 'status_badge',
    BRAND_BADGE = 'brand_badge'
}
