<!-- Root Container for UserProfile  -->
<mat-card class="container profile-data">
    <!-- Page Header Container-->
    <mat-card-header class="mat-card-center">
        <button mat-stroked-button class="margin-right" color="primary" (click)="changePassword()">Change
            Password</button>
        <!-- Button for editting user profile Details -->
        <button mat-icon-button class="edit-btn" (click)="editProfile()">
            <mat-icon>mode_edit</mat-icon>
        </button>
        <!-- Display user sign in details -->

        <img mat-card-avatar src="../../../../assets/account_circle-black-18dp.svg" alt="Avatar">

    </mat-card-header>
    <!-- Display User Profile -->
    <mat-card-content class="mat-card-center">

        <mat-card-title>{{userModel?.first_name}} {{userModel?.last_name}}</mat-card-title>
        <!-- to show user name -->
        <div class="row">
            <div class="col">
                <div class="inliner">

                    <p *ngIf="userModel?.last_sign_in_at">Last Activity:
                        {{userModel?.last_sign_in_at | date:'medium' }}</p>
                </div>
            </div>
            <div class="col">
                <div class="inliner">
                    <!-- to show user email -->
                    <mat-icon>email</mat-icon>
                    <span *ngIf="userModel?.email" class="label-span">{{userModel?.email}}</span>
                </div>
            </div>
            <!-- to show user phone no -->
            <div class="col">
                <div class="inliner">
                    <mat-icon>call</mat-icon>
                    <span *ngIf="userModel?.phone" class="label-span">{{userModel?.phone}}</span>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
